<template>
  <pz-form-flujo
    v-model="formulario3['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>

    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario3"
      status-icon
      :rules="rules_formulario3"
    >
      <label>{{ $t('forms_flujo.form2_persona.BNF.datos_laborales') }}</label>
      <hr
        class="pz-dotted"
        style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.empresa_trabajo"
            :label="$t('forms_flujo.form2_persona.BNF.empresa_trabajo')"
            :error="errors.get('info_extra.empresa_trabajo')"
          >
            <el-input
              v-model="formulario3.info_extra.empresa_trabajo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form2_persona.BNF.empresa_trabajo')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.cargo_ocupa"
            :label="$t('forms_flujo.form2_persona.BNF.cargo_ocupa')"
            :error="errors.get('info_extra.cargo_ocupa')"
          >
            <el-input
              ref="info_extra.cargo_ocupa"
              v-model="formulario3.info_extra.cargo_ocupa"
              :type="'text'"
              :placeholder="$t('forms_flujo.form2_persona.BNF.cargo_ocupa')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.profesion"
            :label="$t('forms_flujo.form2_persona.BNF.profesion')"
            :error="errors.get('info_extra.profesion')"
          >
            <el-select
              ref="info_extra.profesion"
              v-model="formulario3.info_extra.profesion"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.BNF.profesion')"
            >
              <el-option
                v-for="item in data_profesion"
                :key="item.id"
                :label="item.name"
                :value="item.name"
                :placeholder="$t('forms_flujo.form2_persona.BNF.profesion')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.direccion_trabajo"
            :label="$t('forms_flujo.form2_persona.BNF.direccion')"
            :error="errors.get('info_extra.direccion_trabajo')"
          >
            <el-input
              ref="info_extra.direccion_trabajo"
              v-model="formulario3.info_extra.direccion_trabajo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form2_persona.BNF.direccion')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <label>{{ $t('forms_flujo.form2_persona.BNF.ingresos') }}</label>
      <hr
        class="pz-dotted"
        style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form2_persona.BNF.sueldo')"
            :error="errors.get('ingreso_mensual')"
          >
            <money
              ref="ingreso_mensual"
              v-model="formulario3.ingreso_mensual"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.sueldo')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.honorarios"
            :label="$t('forms_flujo.form2_persona.BNF.honorarios')"
            :error="errors.get('info_extra.honorarios')"
          >
            <money
              ref="info_extra.honorarios"
              v-model="formulario3.info_extra.honorarios"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.honorarios')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingresos_conyuge"
            :label="$t('forms_flujo.form2_persona.BNF.ingreso_conyugue')"
            :error="errors.get('ingresos_conyuge')"
          >
            <money
              v-model="formulario3.ingresos_conyuge"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.ingreso_conyugue')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.ingresos_otros"
            :label="$t('forms_flujo.form2_persona.BNF.ingresos_otros')"
            :error="errors.get('info_extra.ingresos_otros')"
          >
            <money
              ref="info_extra.ingresos_otros"
              v-model="formulario3.info_extra.ingresos_otros"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.ingresos_otros')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <label>{{ $t('forms_flujo.form2_persona.BNF.gastos') }}</label>
      <hr
        class="pz-dotted"
        style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.gastos_familiares"
            :label="$t('forms_flujo.form2_persona.BNF.gastos_familiares')"
            :error="errors.get('info_extra.gastos_familiares')"
          >
            <money
              ref="info_extra.gastos_familiares"
              v-model="formulario3.info_extra.gastos_familiares"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.gastos_familiares')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.gastos_tarjeta_credito"
            :label="$t('forms_flujo.form2_persona.BNF.gastos_tarjeta_credito')"
            :error="errors.get('info_extra.gastos_tarjeta_credito')"
          >
            <money
              ref="info_extra.gastos_tarjeta_credito"
              v-model="formulario3.info_extra.gastos_tarjeta_credito"
              v-money="money"
              class="el-input__inner"
              :placeholder="$t('forms_flujo.form2_persona.BNF.gastos_tarjeta_credito')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.gastos_cuota_prestamo"
            :label="$t('forms_flujo.form2_persona.BNF.gastos_cuota_prestamo')"
            :error="errors.get('info_extra.gastos_cuota_prestamo')"
          >
            <money
              v-model="formulario3.info_extra.gastos_cuota_prestamo"
              v-money="money"
              class="el-input__inner"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.gastos_otros"
            :label="$t('forms_flujo.form2_persona.BNF.gastos_otros')"
            :error="errors.get('info_extra.gastos_otros')"
          >
            <money
              v-model="formulario3.info_extra.gastos_otros"
              v-money="money"
              class="el-input__inner"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required} from "@/commons/utils/Rules";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import currency from "@/commons/filters/other/currency";
    import {Money} from 'v-money'
    import d_profesion from "@/assets/data/profesion";

    export default {
        name: 'PzPersonFormExt2Bnf',
        components: {PzFormFlujo, Money},
        mixins: [baseform2],
        data() {
            var check_Jubilado = (rule, value, callback) => {
                if (!this.isjubilado && !value) {
                    return callback(new Error(this.$t('rules.incomplete')));
                }
                return callback();
            };
            return {
                data_profesion: d_profesion,
                isjubilado:false,
                formulario3: {
                    tipo_trabajo: '',
                    ingreso_mensual:  0,
                    ingresos_conyuge:  0,
                    info_extra: {
                        empresa_trabajo: '',
                        tipo_trabajo: '',
                        direccion_trabajo: '',
                        honorarios:  0,
                        gastos_familiares:  0,
                        gastos_tarjeta_credito:  0,
                        gastos_cuota_prestamo:  0,
                        gastos_otros: 0,
                        profesion: '',
                        cargo_ocupa: '',
                        ingresos_otros: 0

                    },

                },
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: false
                },
                disable: true,
                rules_formulario3:
                    {
                        tipo_trabajo: [
                            required('')
                        ],
                        ingreso_mensual: [
                            required(''),
                        ],


                        info_extra: {
                            direccion: [required('')],
                            empresa_trabajo: [{validator: check_Jubilado, trigger: 'blur'}],
                            // honorarios: [type({field: '', type: 'number'})],
                            // gastos_familiares: [type({field: '', type: 'number'})],
                            // gastos_tarjeta_credito: [type({field: '', type: 'number'})],
                            // gastos_cuota_prestamo: [type({field: '', type: 'number'})],
                            // gastos_otros: [type({field: '', type: 'number'})],
                        }
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },
        created: function () {
            let data = localStorage.getItem('isjubilado')
            if(data && data=='true')
                this.isjubilado =  true;
            else
                this.isjubilado = false;
        },
        beforeMount() {
        },
        methods: {
            formatearNumero(eve){
                eve.target.value =currency(eve.target.value,'',2)

            }
        },
    }
</script>

